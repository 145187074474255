<template>
  <v-container fluid>
    <v-row
      justify="center"
      :style="style_comingsoon"
    >
      <h1 class="font-weight-light text-center">
        Work in progress... <br>
        Thank you for your understanding.
        <br><br>
      </h1>
      <v-img
        src="@/assets/lego.png"
        :width="this.$store.state.windowW"
      />
    </v-row>
  </v-container>
</template>

<script>
  import config from '@/config'

  export default {
    data: () => ({
      // Couleur
      red: config.colors.gricad_red_dark1,
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      barchart_color: config.colors.gricad_blue_dark3,
      blue: config.colors.gricad_oceanblue,
      bg_dark: config.colors.gricad_darkblue_dark2,
    }),

    computed: {
      style_centerblock () {
        return {
          height: `${this.$store.state.windowH - 400}px`,
          'background-color': 'transparent',
          'font-size': '30px',
        }
      },
      style_comingsoon () {
        return {
          'font-size': '20px',
          margin: '0px 0px 0px 0px',
        }
      },
      style_bigbtn () {
        return {
          height: `${this.$store.state.windowH / 4}px`,
          width: `${this.$store.state.windowW / 4}px`,
          'background-color': this.blue,

        }
      },
    },

  }
</script>

<style lang="scss">
</style>
